<template>
  <a-card>
    <div slot="title">基本信息</div>
    <a-form-model v-bind="$formItemLayout4DetailSm">
      <a-form-model-item label="名称：">
        <span>{{ serviceData.name }}</span>
      </a-form-model-item>
      <a-form-model-item label="服务类型：">
        <span>{{ serviceData.type | ServiceManagerTypeText }}</span>
      </a-form-model-item>
      <a-form-model-item label="介绍：">
        <span>{{ serviceData.introduction }}</span>
      </a-form-model-item>
      <a-form-model-item label="服务特色：">
        <span>{{ serviceData.characteristic }}</span>
      </a-form-model-item>
      <a-form-model-item label="视频介绍：">
        <a :href="serviceData.videoUrl" target="blank_" class="content-label">
          <a-icon type="file" style="margin-right:8px" />{{ serviceData.videoUrl }}
        </a>
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import * as serviceManagerApi from '@/api/serviceManager'
export default {
  name: 'ServiceManagerDetail',
  data() {
    return {
      serviceData: {
        name: '',
        type: '',
        creator: '',
        introduction: '',
        characteristic: '',
        videoUrl: ''
      }
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      const {
        $route: {
          params: { id }
        }
      } = this
      serviceManagerApi.detail(id).then(res => {
        const { name, type, introduction, characteristic, videoUrl } = res.data
        this.serviceData = { name, type, introduction, characteristic, videoUrl }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-form-item-control-wrapper {
  padding-left: 16px;
}
.content-label {
  white-space: normal;
  word-break: break-all;
}
</style>
